
import {defineComponent} from "vue";
import {Swiper} from 'swiper/vue/swiper-vue';
import {EffectCreative, Navigation} from 'swiper';

import ArrowLeft from '@/assets/icons/arrow-left.svg'
import ArrowRight from '@/assets/icons/arrow-right.svg'

export default defineComponent({
    components: {
        Swiper
    },
    setup() {
        let randomString = Math.random().toString(36).slice(2);
        
        return {
            randomString: randomString,
            centeredSlides: true,
            slidesPerView: 1,
            allowTouchMove: true,
            modules: [EffectCreative, Navigation],
            navigation: {
                nextEl: "#next" + randomString,
                prevEl: "#previous" + randomString,
            },
            breakpoints: {
                769: {
                    allowTouchMove: false
                }
            },
            effect: "creative",
            creativeEffect: {
                limitProgress: 2,
                prev: {
                    translate: ["-100%", 0, -1],
                    opacity: 0.5,
                    scale: 1.1,
                },
                next: {
                    translate: ["100%", 0, -1],
                    opacity: 0.5,
                    scale: 1.1,
                },
            },
            speed: 600
        }
    },
    computed: {
        ArrowLeft() {return ArrowLeft;},
        ArrowRight() {return ArrowRight;},
    }
});
