
import {EffectCreative, Navigation} from 'swiper';
import {Swiper} from 'swiper/vue/swiper-vue';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css'
import {defineComponent} from "vue";
import ArrowLeft from '@/assets/icons/arrow-left.svg';
import ArrowRight from '@/assets/icons/arrow-right.svg';

export default defineComponent({
    components: {
        Swiper
    },
    setup() {
        let randomString = Math.random().toString(36).slice(2);
        return {
            randomString: randomString,
            modules: [EffectCreative, Navigation],
            slidesPerView: 1,
            speed: 500,
            navigation: {
                nextEl: "#next" + randomString,
                prevEl: "#previous" + randomString
            },
            breakpoints: {
                769: {
                    allowTouchMove: false
                }
            },
            effect: "creative",
            creativeEffect: {
                prev: {
                    translate: [0, 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            }
        }
    },
    computed: {
        ArrowLeft() {return ArrowLeft;},
        ArrowRight() {return ArrowRight;},
    }
});
