
import {defineComponent} from 'vue';
import IconChevronDown from '@/assets/icons/icons-chevron-down.svg?inline'
import IconPlay from '@/assets/icons/icon-play-icon.svg?inline'

export default defineComponent({
    props: {
        eyeBrow: {$type: String, required: true},
        text: {$type: String, required: true},
        lblDiscoverMore: {$type: String, required: true},
        lblWatchVideo: {$type: String, required: true},
        videoPreviewPoster: {$type: String, required: true},
        videoPreviewUrl: {$type: String, required: true},
        videoFullUrl: {$type: String, required: true},
    },
    methods: {
        exitFullscreen() {
            const videoPreview = this.$refs.videoPreview as HTMLVideoElement;
            const videoFull = this.$refs.videoFull as HTMLVideoElement;
            if(!document.fullscreenElement) {
                videoFull.pause();
                videoFull.classList.remove("active");
                videoFull.currentTime = 0;
                videoPreview.play();
                document.removeEventListener("fullscreenchange", this.exitFullscreen);
            }
        },
        enterFullScreen() {
            if(!this.$refs.videoFull) return;
            const videoPreview = this.$refs.videoPreview as HTMLVideoElement;
            const videoFull = this.$refs.videoFull as HTMLVideoElement;
            
            videoPreview.pause();
            videoFull.classList.add("active");
            videoFull.play();
            videoFull.requestFullscreen()
                .then(() => {
                    document.addEventListener("fullscreenchange", this.exitFullscreen);
                });
        }
    },
    computed: {
        IconChevronDown() {return IconChevronDown;},
        IconPlay() {return IconPlay;},
    }
});
