import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "video-hero__tag" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 0,
  class: "video-hero__button-discover segmented-button segmented-button--full segmented-button--green",
  href: "#about-us-section"
}
const _hoisted_4 = { class: "segmented-button__label" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "segmented-button__label" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["poster"]
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  ref: "videoFull",
  class: "video-hero__full-video",
  preload: "none"
}
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.eyeBrow), 1),
    _createElementVNode("h1", {
      class: "video-hero__text",
      innerHTML: _ctx.text
    }, null, 8, _hoisted_2),
    (_ctx.lblDiscoverMore)
      ? (_openBlock(), _createElementBlock("a", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.lblDiscoverMore), 1),
          _createElementVNode("span", {
            class: "segmented-button__icon",
            innerHTML: _ctx.IconChevronDown
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      class: "video-hero__button-video segmented-button segmented-button--full segmented-button--semi-transparent",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.enterFullScreen && _ctx.enterFullScreen(...args)))
    }, [
      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.lblWatchVideo), 1),
      _createElementVNode("span", {
        class: "segmented-button__icon",
        innerHTML: _ctx.IconPlay
      }, null, 8, _hoisted_7)
    ]),
    _createElementVNode("video", {
      ref: "videoPreview",
      class: "video-hero__background",
      autoPlay: "",
      muted: "",
      playsInline: "",
      loop: "",
      poster: _ctx.videoPreviewPoster
    }, [
      _createElementVNode("source", {
        src: _ctx.videoPreviewUrl,
        type: "video/mp4"
      }, null, 8, _hoisted_9)
    ], 8, _hoisted_8),
    _createElementVNode("video", _hoisted_10, [
      _createElementVNode("source", {
        src: _ctx.videoFullUrl,
        type: "video/mp4"
      }, null, 8, _hoisted_11)
    ], 512)
  ], 64))
}