import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "usp-component dark-bg" }
const _hoisted_2 = { class: "usp-component__swiper swiper-layout" }
const _hoisted_3 = ["id", "innerHTML"]
const _hoisted_4 = ["id", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "usp-component__button-previous swiper-layout__button-previous",
        id: 'previous' + _ctx.randomString,
        innerHTML: _ctx.ArrowLeft
      }, null, 8, _hoisted_3),
      _createVNode(_component_swiper, {
        allowTouchMove: true,
        modules: _ctx.modules,
        navigation: _ctx.navigation,
        breakpoints: _ctx.breakpoints,
        createElements: false,
        effect: _ctx.effect,
        creativeEffect: _ctx.creativeEffect,
        slidesPerView: _ctx.slidesPerView
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }, 8, ["modules", "navigation", "breakpoints", "effect", "creativeEffect", "slidesPerView"]),
      _createElementVNode("div", {
        class: "usp-component__button-next swiper-layout__button-next",
        id: 'next' + _ctx.randomString,
        innerHTML: _ctx.ArrowRight
      }, null, 8, _hoisted_4)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}