import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "product-wrapper__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_product_slider = _resolveComponent("product-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_product_slider, { key: 0 }, {
          slides: _withCtx(() => [
            _renderSlot(_ctx.$slots, "slides")
          ]),
          _: 3
        }))
      : _renderSlot(_ctx.$slots, "default", { key: 1 })
  ]))
}