
import { defineComponent, ref, onMounted, onUnmounted, computed } from "vue";
import ProductSlider from "@/components/ProductSlider.vue";

export default defineComponent({
  components: {
    ProductSlider
  },
  setup() {
    const windowWidth = ref(window.innerWidth);
    const isMobile = computed(() => windowWidth.value < 500);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize);
    });

    return {
      windowWidth,
      isMobile,
      handleResize
    };
  }
});
